import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';

import { NGXLogger } from 'ngx-logger';
import { AngularFirestore } from "@angular/fire/compat/firestore";

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';


import { Azienda } from 'src/app/models/fragile/azienda.model';

import { AziendeService } from 'src/app/services/aziende.service';
import { CreaAziendaComponent } from './crea/crea-azienda';
import { ModificaAziendaComponent } from './modifica/modifica-azienda';
import { Router } from '@angular/router';

declare const CSVBoxImporter: any;

@Component({
    templateUrl: './aziende.component.html',
    styleUrls: ['./aziende.component.css'],
    providers: [DialogService, ConfirmationService, MessageService],

})
export class AziendeComponent implements OnInit, OnDestroy {
    protected aziende:Azienda[]=[];
    protected selectedAzienda:Azienda;

    protected isLoadingResults:boolean = false;
    protected showDeleteConfirmation:boolean = false;

    private ref: DynamicDialogRef;

    @ViewChild("aziendeiDT", { static: true }) aziendeiDT: any;

    constructor(
        private logger: NGXLogger,
        private firestore: AngularFirestore,
        private dialogService: DialogService,
        private messageService: MessageService,
        private aziendeService:AziendeService,
        private router: Router
    ) {}

    ngOnDestroy(): void {
    }

    ngOnInit() {
        this.logger.info("INIT AZIENDE ");
        this.loadAziende();
    }

    loadAziende(){
        this.logger.info("LOADING AZIENDE");
        this.aziendeService.get().subscribe( {
            next: (res) => {
                this.aziende = res;
            },
            error: (e) => {
                this.logger.info(e);
              this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di caricamento aziende'});
              this.isLoadingResults = false;
          },
            complete: () => {

              this.isLoadingResults = false;
            }
        });
    }
    

      getAziendaClass(azienda: Azienda): string {
        if (azienda.ragioneSociale.toUpperCase() === "VERTICAL SRL") {
          return "azienda-vertical";
        }else{
          return "azienda-altre";
        }
      }



      filtraAziende(event: any){
        this.aziendeiDT.filterGlobal(event.target.value, 'contains');
      }

       openCreaAziendaDialog(){
         this.ref = this.dialogService.open(CreaAziendaComponent, {
            header: "Nuova Azienda",
            width:  "50%" ,
            height: "40%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
          this.ref.onClose.subscribe((confirmNewAzienda) => {
             console.log("AFTER CLOSED > "+ confirmNewAzienda.success);
            if (confirmNewAzienda && confirmNewAzienda.success) {
              this.messageService.add({severity:'success', summary:'', detail:'Azienda creata con successo!', life:3000});
              this.loadAziende();
            }
          }); 

      } 


    openDeleteAzienda(azienda: Azienda): void {
        this.showDeleteConfirmation = true;
        this.selectedAzienda = azienda;
        this.logger.info(this.selectedAzienda)
     }



    confermaEliminazioneAzienda(){
      this.showDeleteConfirmation = false;
      this.isLoadingResults = true;
      this.aziendeService.elimina(this.selectedAzienda.id).subscribe( {
          next: (v) => {

          },
          error: (e) => {
              this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di eliminazione azienda'});
              this.isLoadingResults = false;
          },
          complete: () => {
              this.messageService.add({severity:'success', summary:'', detail:'Azienda eliminata!', life:5000});
            this.loadAziende();
              this.isLoadingResults = false;
              
          }
      });

    }

    openProgettiAzienda(azienda: Azienda): void {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['progetti/azienda/'+azienda.id]));
    }
    openUtentiAzienda(azienda: Azienda): void {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['utenti/azienda/'+azienda.id]));
    }

     openModificaAziendaDialog(azienda: Azienda): void {
        this.ref = this.dialogService.open(ModificaAziendaComponent, {
            data: {
                azienda: azienda,
              },
            header: "Modifica Azienda",
            width:  "50%" ,
            height: "60%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
          this.ref.onClose.subscribe((confirmModificaAzienda) => {
             console.log("AFTER CLOSED > "+ confirmModificaAzienda.success);
            if (confirmModificaAzienda && confirmModificaAzienda.success) {
              this.messageService.add({severity:'success', summary:'', detail:'Azienda modificata con successo!', life:3000});
              this.loadAziende();
            }
          }); 
    } 








}
