import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";

import {DOCUMENT} from "@angular/common";
import {NGXLogger} from "ngx-logger";
import {TabViewChangeEvent} from "primeng/tabview";
import {environment} from "../../../environments/environment";
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { Timestamp, onSnapshot } from "firebase/firestore";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { Misurazione_Firestore } from "src/app/models/firestore/misurazioneFirestore.model";
import { ChartModule } from 'primeng/chart';
import { jwtDecode } from "jwt-decode";
import { JwtHelperService } from '@auth0/angular-jwt';
import { SecurityService } from "src/app/services/security.service";

@Component({
  templateUrl: "./misurazioni.component.html",
  styleUrls: ["./misurazioni.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class MisurazioniComponent implements OnInit, AfterViewInit {
 
  protected isLoading: boolean = true;
  public isReady: boolean = false;
  protected showLine: boolean = false;
  protected tokenValid: boolean = false;

  protected idProgetto:number;
  protected idPunto:number;
  protected misurazione:Misurazione_Firestore;
  public misurazioni_punto:Misurazione_Firestore;

  ref: DynamicDialogRef;
  options: any;
  data: any;


  constructor(
    private logger: NGXLogger,
    private firestore: AngularFirestore,
    private router: Router,
    public dialogService: DialogService,
    private messageService:MessageService,
    private activatedRoute: ActivatedRoute,
    private securityService:SecurityService
  ) {
   
  }

  ngOnInit() {
    this.logger.info("INIT @ MISURAZIONI");
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.options = {
      /* legend: {
        display: false       
      },
      title: {
        display: true,
        text: ''
      }, */
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {display:false} 
          /* legend: {
              labels: {
                  color: textColor
              }
          } */
      },
      scales: {
          x: {
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          },
          y: {
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          }
      }
  };
  
  const urlParamIDPunto   = this.activatedRoute.snapshot.paramMap.get('idPunto');
  const urlParamIDProgetto   = this.activatedRoute.snapshot.paramMap.get('idProgetto');
  if(urlParamIDProgetto!=null){
    this.idProgetto = Number(urlParamIDProgetto);
  }
  if(urlParamIDPunto!=null){
    this.idPunto = Number(urlParamIDPunto);
  }
  this.logger.info("progetto "+ this.idProgetto);
  this.logger.info("punto "+ this.idPunto);

  this.activatedRoute.queryParams.subscribe(params => {
    this.logger.info( params);

    const token = params['token'];
     if (token) {
      this.validateToken(token);
    } 
   /* this.tokenValid = true;
      this.loadMisurazioniPunto(this.idProgetto, this.idPunto); */
    this.showLine = params['showline'] === 'true';
  });

  //https://measura.map-hosting.it/misurazione/13/200?showline=true%3EApri
  }

  

   validateToken(token:String): void {
    this.logger.info("parte valida token "+ token);

    this.securityService.validateToken(token).subscribe({
      next: (res) => {
        this.tokenValid = true;
        this.logger.info("OK VALIDAZIONE TOKEN > "+ this.idPunto );
        this.loadMisurazioniPunto(this.idProgetto, this.idPunto);
        
      },
      error: (error) => {
        if (error.status === 401) {
          this.logger.error("Unauthorized: Token non valido o scaduto.");
        } else {
          this.logger.error("Errore nella chiamata HTTP:", error);
        }
      },
      complete: () => {
        this.logger.info("Chiamata completata.");
      }
    });
  } 


  ngAfterViewInit() {
  }

  setTimeRange(range: string) {
    const labels = Object.keys(this.misurazione.misurazioni).filter(key => key !== 'ID');

    const values = labels.map(label => this.misurazione.misurazioni[label]);
    const dateLabels = labels.map(label => { 

      return new Date(
      parseInt(label.substring(1, 5)), // Anno
      parseInt(label.substring(5, 7)) - 1, // Mese (zero-based)
      parseInt(label.substring(7,9)) // Giorno
    )});

    let filteredLabels: string[] = [];
    let filteredValues: number[] = [];

    const now = new Date();
    let startDate: Date;

    switch (range) {
      case '1M':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case '6M':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case 'ALL':
        default:
          startDate = new Date(0); // Data molto passata per includere tutti i dati
          break;
    }
    for (let i = 0; i < dateLabels.length; i++) {

      if (dateLabels[i] >= startDate) {
        filteredLabels.push(labels[i]);
        filteredValues.push(values[i]);
      }
    }
    this.data = {
      labels: filteredLabels,
      datasets: [
        {
          data: filteredValues,
          fill: false,
          borderColor: '#42A5F5',
          tension: 0.4,
          pointRadius: 3, // Dimensione dei punti
          showLine: this.showLine // Imposta come desiderato
        }
      ]
    };

    
  }



  loadMisurazioniPunto (idProgetto:number, idPunto:number) {
    this.logger.info("-> loadMisurazioniPunto "+ idProgetto + "/"+idPunto);
    const collectionRef = this.firestore.collection(environment.firebase.collectionMisurazioni).ref.where('idProgetto', "==", idProgetto).where('idPunto', "==", idPunto);
    let queryMisurazioni = collectionRef;

    onSnapshot(queryMisurazioni, (qs) => {

        qs.docs.map((item) => {

          this.misurazione = item.data() as Misurazione_Firestore;

          const labels = Object.keys(this.misurazione.misurazioni).filter(key => key !== '\"SPAZIO\"');
          const values = labels.map(label => this.misurazione.misurazioni[label]);
          
         
           this.data = {
            labels: labels,
            datasets: [
              {
                //label: 'Misurazioni',
                data: values,
                fill: false,
                borderColor: '#42A5F5',
                tension: 0.4,
                pointRadius: 3,      // Dimensione dei punti
                showLine: this.showLine  ,
    
              }
            ]  
          };  
       
         
      
      });
      
    });
  }

}
