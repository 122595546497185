import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Azienda } from '../models/fragile/azienda.model';
import { Progetto } from '../models/fragile/progetto.model';


@Injectable({
  providedIn: 'root',
})
export class ProgettiService{
  apiUrl: string = environment.apiUrl +"progetto";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  crea(progetto:Progetto): Observable<any> {
    const formdata = new FormData();
    formdata.append("identificativo", progetto.identificativo.valueOf());
    formdata.append("descrizione", progetto.descrizione?progetto.descrizione.valueOf():'');
    formdata.append("idAzienda", progetto.idAzienda.valueOf());
    if(progetto.fileMisurazioni!=null){
      formdata.append("fileMisurazioni", progetto.fileMisurazioni, progetto.fileMisurazioni.name);
    }
    formdata.append("fileQGis", progetto.fileQGis, progetto.identificativo+"qgs");
    this.logger.info("--> CREAZIONE progetto con geopackage chiamato "+ progetto.fileGeoPackage.name);
    formdata.append("fileGeoPackage", progetto.fileGeoPackage, progetto.fileGeoPackage.name);
    formdata.append("nomeFileGeoPackage", progetto.fileGeoPackage.name);
    //formdata.append("fileGeoPackage", progetto.fileGeoPackage, progetto.identificativo+"gpkg");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, formdata, httpOptions);

  }

  modifica(progetto:Progetto): Observable<any> {
    const formdata = new FormData();
    if(progetto.identificativo){
      formdata.append("identificativo", progetto.identificativo.valueOf());
    }
    if(progetto.descrizione){
      formdata.append("descrizione", progetto.descrizione?progetto.descrizione.valueOf():'');
    }
    if(progetto.idAzienda){
      formdata.append("idAzienda", progetto.idAzienda.valueOf());
    }
    if(progetto.fileMisurazioni!=null){
      formdata.append("fileMisurazioni", progetto.fileMisurazioni, progetto.fileMisurazioni.name);
    }
    if(progetto.fileQGis!=null){
      formdata.append("fileQGis", progetto.fileQGis, progetto.identificativo+"qgs");
    }
    if(progetto.fileGeoPackage!=null){
      this.logger.info("--> modifico progetto con geopackage chiamato "+ progetto.fileGeoPackage.name);
      formdata.append("fileGeoPackage", progetto.fileGeoPackage, progetto.fileGeoPackage.name);

    }
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+progetto.id, formdata, httpOptions);

  }

  caricaMisurazioni(progetto:Progetto, isOverwrite:string): Observable<any> {
    const formdata = new FormData();
    formdata.append("fileMisurazioni", progetto.fileMisurazioni, progetto.fileMisurazioni.name);
    formdata.append("overwrite", isOverwrite);

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl+"/"+progetto.id+"/carica-misurazioni",formdata, httpOptions);
  }
  eliminaMisurazioni(idProgetto:number): Observable<any> {
    const formdata = new FormData();
   
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl+"/"+idProgetto+"/elimina-misurazioni", httpOptions);
  }

  getStoricoCaricamenti(idProgetto:Number): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/"+idProgetto+"/storico-caricamenti", httpOptions);
  }



  elimina(idProgetto:Number): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl+"/"+idProgetto, httpOptions);
  }




}
