
export class Progetto {
    id:               Number;
    identificativo:   String;
    descrizione:      String;
    idAzienda:        String;
    fileMisurazioni:  File;
    fileQGis:         File;
    fileGeoPackage:   File;
  }
