import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";

import {DOCUMENT} from "@angular/common";
import {NGXLogger} from "ngx-logger";
import {TabViewChangeEvent} from "primeng/tabview";
import {environment} from "../../../environments/environment";
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { Timestamp, onSnapshot } from "firebase/firestore";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MenuItem, MessageService, PrimeIcons } from "primeng/api";
import { Misurazione_Firestore } from "src/app/models/firestore/misurazioneFirestore.model";
import { ChartModule } from 'primeng/chart';
import {AuthService} from "@auth0/auth0-angular";
import { HttpClient } from '@angular/common/http';
import { UserService } from "src/app/services/user.service";
import { Progetto_Firestore } from "src/app/models/firestore/progettoFirestore.model";
import { UtilService } from "src/app/services/util.service";
import { STILI_STATO_CARICAMENTO, STILI_STATO_PROGETTO } from "src/app/shared/stili";
import { Azienda } from "src/app/models/fragile/azienda.model";
import { AziendeService } from "src/app/services/aziende.service";
import { CreaProgettoComponent } from "./crea-progetto/crea-progetto";
import { Menu } from "primeng/menu";
import { ProgettiService } from "src/app/services/progetti.service";
import { StoricoCaricamentiComponent } from "./storico-caricamenti/storico-caricamenti.component";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import { getStorage, ref, getBlob } from "firebase/storage";
import { saveAs } from "file-saver";
import { CaricaFileQGIComponent } from "./carica-file/carica-qgis";
import { CaricaFileGEOPackageComponent } from "./carica-file/carica-geoPackage";
import { CaricaFileCSVComponent } from "./carica-file/carica-csv";
import { ModificaProgettoComponent } from "./modifica-progetto/modifica-progetto";

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [DialogService, ConfirmationService, MessageService],
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild("progettiDT", { static: true }) progettiDT: any;

  @ViewChild("menuAzioniTiered", { static: true }) azioniMenuTiered: Menu;

  protected isLoadingResults: boolean = true;
  protected showDeleteConfirmation: boolean = false;
  protected showCaricaMisurazioniConfirmation: boolean = false;
  protected showEliminaMisurazioniConfirmation: boolean = false;
  public isReady: boolean = false;
 
  public misurazioni_punto:Misurazione_Firestore;
  private progetti: Progetto_Firestore[] = [];
  public selectedProgetto:Progetto_Firestore;
  protected aziende:Azienda[]=[];

  ref: DynamicDialogRef;
  options: any;
  data: any;

  private collection_progetti:string = environment.firebase.collectionProgetti;
  protected stiliStatoCaricamento = STILI_STATO_CARICAMENTO;
  protected stiliStatoProgetto = STILI_STATO_PROGETTO;

  protected menuAzioniItems!: MenuItem[];


  constructor(
    public auth: AuthService,
    private logger: NGXLogger,
    private firestore: AngularFirestore,
    private router: Router,
    public dialogService: DialogService,
    private messageService:MessageService,
    @Inject(DOCUMENT) private doc: Document,
    private http: HttpClient,
    private userService: UserService,
    public utilService:UtilService,
    private aziendeService:AziendeService,
    private progettiService:ProgettiService,
    private googleStorageService:GoogleStorageService,
    private confirmationService: ConfirmationService,

  ) {
    this.isLoadingResults= true;
    this.loadAziende();
    
  }

  ngOnInit() {
    this.logger.info("ONINIT @ HOME");

    this.auth.user$.subscribe((user) => {
      if (user != null) {
        //this.logger.info(user);

        this.userService.loadSetUserInformation(user).then(() => {
          this.loadProgetti();

        });
      
      }});   
   
  
  }


  ngAfterViewInit() {
  }

  async openCaricaAzioni(  event: any, progetto:Progetto_Firestore): Promise<void> {
    this.selectedProgetto =  progetto ;
    this.loadAzioni(event, progetto );
    this.azioniMenuTiered.toggle(event);
  }
  loadAzioni(event:any, progetto:Progetto_Firestore) {
    this.menuAzioniItems = [];
    this.menuAzioniItems.push({
        label: "Visualizza",
        icon: PrimeIcons.CHEVRON_CIRCLE_RIGHT,
        style: { 
          'font-size': '13px',
          'font-weight': 'bold'
        },
        command: () => this.openViewer(progetto),
      }
    );
    if(this.userService.isAdmin){
      this.menuAzioniItems.push(
        {
          label: "Modifica",
          icon: PrimeIcons.PENCIL,
          style: { 
            'font-size': '13px',
            'font-weight': 'bold'
          },
          items: [
            {
              label: "Dettagli",
              icon: PrimeIcons.INFO_CIRCLE,
              style: { 'font-size': '12.5px'},
              command: () => this.modificaProgetto(progetto),
            },
            {
              label: "Ricarica File QGIS",
              icon: PrimeIcons.MAP_MARKER,
              style: { 'font-size': '12px'},
              command: () => this.ricaricaFile(progetto, true),
            },
            {
              label: "Ricarica File GeoPackage",
              icon: PrimeIcons.MAP,
              style: { 'font-size': '12px'},
              command: () => this.ricaricaFile(progetto, false),
            },
          ]
          //command: () => this.modificaProgetto(progetto),
        });
        let itemsMisurazioni = [];
       
        if(!progetto.statoCaricamento){
          itemsMisurazioni.push({
            label: "Carica",
            icon: PrimeIcons.CLOUD_UPLOAD,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, false),
          });
        }else if( progetto.statoCaricamento==='ELIMINATO'){
          itemsMisurazioni.push({
            label: "Storico",
            icon: PrimeIcons.HISTORY,
            style: { 'font-size': '13px'},
            command: () => this.apriStoricoMisurazioni(progetto, false),
          },{
            label: "Carica",
            icon: PrimeIcons.CLOUD_UPLOAD,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, true),
          }
        );
        }else if( progetto.statoCaricamento==='IN_CORSO'){
         
        }else if(progetto.statoCaricamento==='COMPLETATO'){
          itemsMisurazioni.push({
            label: "Storico",
            icon: PrimeIcons.HISTORY,
            style: { 'font-size': '13px'},
            command: () => this.apriStoricoMisurazioni(progetto, false),
          },{
            label: "Carica",
            icon: PrimeIcons.CLOUD_UPLOAD,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, false),
          }
        );
          itemsMisurazioni.push( {
            label: "Aggiungi",
            icon: PrimeIcons.PLUS_CIRCLE,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, true),
          });
          itemsMisurazioni.push( {
            label: "Elimina",
            icon: PrimeIcons.ERASER,
            style: { 'font-size': '13px'},
            command: () => this.eliminaMisurazioni(progetto),
          });
        }
        this.menuAzioniItems.push(
        {
        label: "Misurazioni",
        icon: PrimeIcons.LIST,
        style: { 
          'font-size': '13px',
          'font-weight': 'bold'
        },
        items: itemsMisurazioni/*  [
          {
            label: "Storico",
            icon: PrimeIcons.HISTORY,
            style: { 'font-size': '13px'},
            command: () => this.apriStoricoMisurazioni(progetto, false),
          },{
            label: "Carica",
            icon: PrimeIcons.CLOUD_UPLOAD,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, false),
          },
          {
            label: "Aggiorna",
            icon: PrimeIcons.HISTORY,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, true),
          },
        ] */
      },
      {
        label: "Elimina",
        icon: PrimeIcons.TRASH,
        style: { 
          'font-size': '13px',
          'font-weight': 'bold'
        },
        command: () => this.eliminaProgetto(progetto),
      }
    );
    }else if(this.userService.isUtente){
      this.menuAzioniItems.push();
    }
  }

  openViewer(progetto:Progetto_Firestore){
    const url = environment.viewerUrl+progetto.identificativo+"#/map";
    window.open(url, '_blank');
    
  }
  modificaProgetto(progetto:Progetto_Firestore){
    this.ref = this.dialogService.open(ModificaProgettoComponent, {
      data: {
        progetto: progetto,
      },
      header: "Modifica Progetto "+progetto.identificativo.toUpperCase(),
      width:  "70%" ,
      height: "50%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmCaricamentoQGISFile) => {
       console.log("AFTER CLOSED > "+ confirmCaricamentoQGISFile.success);
      if (confirmCaricamentoQGISFile && confirmCaricamentoQGISFile.success) {
        this.messageService.add({severity:'success', summary:'', detail:'Progetto modificato con successo!', life:3000});
        this.loadProgetti();
      }
    });
  }
  ricaricaFile(progetto:Progetto_Firestore, isQGIS:boolean){
    if(isQGIS){
      this.ref = this.dialogService.open(CaricaFileQGIComponent, {
        data: {
          progetto: progetto,
        },
        header: "Carica file QGIS del Progetto "+progetto.identificativo.toUpperCase(),
        width:  "50%" ,
        height: "35%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((confirmCaricamentoQGISFile) => {
         console.log("AFTER CLOSED > "+ confirmCaricamentoQGISFile.success);
        if (confirmCaricamentoQGISFile && confirmCaricamentoQGISFile.success) {
          this.messageService.add({severity:'success', summary:'', detail:'File caricato con successo!', life:3000});
          this.loadProgetti();
        }
      });
    }else{
      this.ref = this.dialogService.open(CaricaFileGEOPackageComponent, {
        data: {
          progetto: progetto,
        },
        header: "Carica file GEOPACKAGE del Progetto "+progetto.identificativo.toUpperCase(),
        width:  "50%" ,
        height: "35%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((confirmCaricamentoQGISFile) => {
         console.log("AFTER CLOSED > "+ confirmCaricamentoQGISFile.success);
        if (confirmCaricamentoQGISFile && confirmCaricamentoQGISFile.success) {
          this.messageService.add({severity:'success', summary:'', detail:'File caricato con successo!', life:3000});
          this.loadProgetti();
        }
      });
    }

  }

  eliminaMisurazioni(progetto:Progetto_Firestore){
    this.showEliminaMisurazioniConfirmation = true;
    this.selectedProgetto = progetto;
  }
  confermaEliminaMisurazioniProgetto(progetto:Progetto_Firestore){
    this.showEliminaMisurazioniConfirmation = false;
    this.isLoadingResults = true;
    this.progettiService.eliminaMisurazioni(this.selectedProgetto.id).subscribe( {
        next: (v) => {

        },
        error: (e) => {
            this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di eliminazione delle misurazioni del progetto '+this.selectedProgetto.identificativo});
            this.isLoadingResults = false;
        },
        complete: () => {
            this.messageService.add({severity:'success', summary:'', detail:'Misurazioni del progetto '+ this.selectedProgetto.identificativo+ ' eliminate!', life:5000});
            this.loadProgetti();
            this.isLoadingResults = false;
            
        }
    });
  }
  caricaMisurazioni(progetto:Progetto_Firestore, isUpdate:boolean){
    if(isUpdate || (!isUpdate && !progetto.statoCaricamento)){
      this.ref = this.dialogService.open(CaricaFileCSVComponent, {
        data: {
          progetto: progetto,
          isOverwrite:false
        },
        header: "Aggiungi misurazioni al progetto "+progetto.identificativo.toUpperCase(),
        width:  "40%" ,
        height: "35%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((confirmNewProgetto) => {
         console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
        if (confirmNewProgetto && confirmNewProgetto.success) {
          this.messageService.add({severity:'success', summary:'', detail:'Misurazioni del progetto caricate con successo!', life:3000});
          this.loadProgetti();
        }
      });
    }else{
      this.showCaricaMisurazioniConfirmation = true;
      this.selectedProgetto = progetto;
      
    }
  }

  confermaCaricaMisurazioniProgetto(progetto:Progetto_Firestore){
    this.showCaricaMisurazioniConfirmation = false;
    this.ref = this.dialogService.open(CaricaFileCSVComponent, {
      data: {
        progetto: this.selectedProgetto,
        isOverwrite:true
      },
      header: "Carica misurazioni del progetto "+this.selectedProgetto.identificativo.toUpperCase(),
      width:  "40%" ,
      height: "35%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmNewProgetto) => {
       console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
      if (confirmNewProgetto && confirmNewProgetto.success) {
        this.messageService.add({severity:'success', summary:'', detail:'Misurazioni del progetto caricate con successo!', life:3000});
        this.loadProgetti();
      }
    });
  }

  async scaricaTemplate(){
    const storage = getStorage();
    const pathReferenceTemplateCaricamenti  = ref( storage, "templates/template_caricamento.csv");

    const blob = await getBlob(pathReferenceTemplateCaricamenti);
    saveAs(blob, "template_caricamenti.csv");
  }
  apriStoricoMisurazioni(progetto:Progetto_Firestore, isUpdate:boolean){
    this.ref = this.dialogService.open(StoricoCaricamentiComponent, {
      data: {
        progetto: progetto,
      },
      header: "Storico dei caricamenti del Progetto "+progetto.identificativo.toUpperCase(),
      width:  "70%" ,
      height: "70%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmNewProgetto) => {
       console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
      if (confirmNewProgetto && confirmNewProgetto.success) {
        this.messageService.add({severity:'success', summary:'', detail:'Progetto creato con successo!', life:3000});
        this.loadProgetti();
      }
    });
  }
  

  eliminaProgetto(progetto:Progetto_Firestore){
    this.showDeleteConfirmation = true;
    this.selectedProgetto = progetto;
  }
 


  confermaEliminazioneProgetto(){
    this.showDeleteConfirmation = false;
    this.isLoadingResults = true;
    this.progettiService.elimina(this.selectedProgetto.id).subscribe( {
        next: (v) => {

        },
        error: (e) => {
            this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di eliminazione del progetto '+this.selectedProgetto.identificativo});
            this.isLoadingResults = false;
        },
        complete: () => {
            this.messageService.add({severity:'success', summary:'', detail:'Progetto '+ this.selectedProgetto.identificativo+ ' eliminato!', life:5000});
            this.loadProgetti();
            this.isLoadingResults = false;
            
        }
    });
  }


  showCaricamentoInCorso(statoCaricamento:String):boolean{
    return statoCaricamento==='IN CORSO';

  }
  

  loadAziende(){
    //this.logger.info("LOADING AZIENDE");
    this.aziendeService.get().subscribe( {
        next: (res) => {
            this.aziende = res;
            this.isLoadingResults = false;
        },
        error: (e) => {
         //   this.logger.info(e);
         // this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di caricamento aziende'});
          this.isLoadingResults = false;
      },
        complete: () => {

          this.isLoadingResults = false;
        }
    });
}

  getAziendaColor(idAzienda:number){
    const aziendaRes = this.aziende.filter((azienda)=>{return azienda.id == idAzienda})[0] as Azienda;
    if(aziendaRes!=null){
      return aziendaRes.colore;
    }
    return "";
  }

  loadProgetti(){
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_progetti).ref;
    let query  ;
    if(this.userService.isAdmin){
      //this.logger.info("LOAD PROGETTI @ HOME PER ADMIN");
      query = collectionRef.orderBy('creato', 'desc');

    }else{
      query = collectionRef.where("idAzienda", "==", this.userService.getUser().azienda.id).orderBy('creato', 'desc');

    }

    onSnapshot(query, (qs) => {
      this.progetti = [];
      this.isLoadingResults = false;

      qs.forEach((doc) => {
        let progetto = doc.data() as Progetto_Firestore;    
        this.progetti.push(progetto);
      });
    });
  }



  openCreaProgettoDialog(){
    this.ref = this.dialogService.open(CreaProgettoComponent, {
      header: "Nuovo Progetto",
      width:  "70%" ,
      height: "70%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmNewProgetto) => {
       console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
      if (confirmNewProgetto && confirmNewProgetto.success) {
        this.messageService.add({severity:'success', summary:'', detail:'Progetto creato con successo!', life:3000});
        this.loadProgetti();
      }
    });
  }
  
  verificaToken(){
    const headers = {
      // 'Authorization': `Bearer ${token}`,
     };
 
     const httpOptions = {
       headers,
       reportProgress: true,
     };
     this.http.get(environment.apiUrl+"security/verify-token", httpOptions).subscribe(
      {
        next: (res) => {
          console.info("OK TEST CALL");
          console.info(res);
        },
        error: (error) => {
          if (error.status === 401) {
            console.error("Unauthorized: Token non valido o scaduto.");
          } else {
            console.error("Errore nella chiamata HTTP:", error);
          }
        },
        complete: () => {
          console.info("Chiamata completata.");
        }
      }
     );
  }



}
