<div class="p-1 md:p-1 lg:p-1">
  <!-- <nav-bar style="z-index: 1000"></nav-bar> -->

  <div *ngIf="auth.user$ | async as user ; else elseBlock">
    <div class="surface-card p-4 shadow-2 border-round">
      <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
        <div class="flex justify-start md:justify-start">
          <span class="p-input-icon-left mr-4">
            <i class="pi pi-search"></i>
            <input type="text" pInputText placeholder="Cerca" (input)="filtraProgetti($event)"
              [disabled]="isLoadingResults" />
          </span>

        </div>
        <div class="flex justify-end md:justify-center">
          <span class="text-xl font-medium text-2000 font-['Poppins']" style="color: #6c092c">PROGETTI</span>
        </div>

        <div class="flex justify-end md:justify-end">

         <!--  <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined"
            style="background-color: rgb(175, 209, 175); color: white;" (click)="loadProgetti()"></button> -->
          <button *ngIf="userService.isAdmin" pButton type="button" icon="pi pi-cloud-download" pTooltip="Scarica template del file con i caricamenti"
            class="inline-flex justify-content-center align-items-center mb-1 mr-1"
            style="background-color:#e4ffcb ; color: #03251e; border-color: #CBEFFF; font-family:'Poppins'; border-color: #e4ffcb ;"
            tooltipPosition="top" label="Template caricamenti" (click)="scaricaTemplate()" [disabled]="isLoadingResults">
          </button>
          <button *ngIf="userService.isAdmin" pButton type="button" icon="pi pi-chart-line" pTooltip="Nuovo Progetto"
            class="inline-flex justify-content-center align-items-center mb-1 mr-1"
            style="background-color:#CBEFFF ; color: #007bff; border-color: #CBEFFF; font-family:'Poppins'"
            tooltipPosition="top" label="Nuovo" (click)="openCreaProgettoDialog()" [disabled]="isLoadingResults">
          </button>
        </div>

      </div>
      <div fxFlex="95%">
        <p-table #progettiDT styleClass="p-datatable-sm" [value]="progetti" dataKey="id" [(selection)]="selectedProgetto"
        [selectionPageOnly]="true" [rowHover]="true" [rows]="30" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[30,50,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
        [globalFilterFields]="['id','identificativo','descrizione']"
        [sortField]="'modificato'" [sortOrder]="-1"
        >
        <ng-template pTemplate="header">
          <tr>
            <th style="font-size: 11px;">IDENTIFICATIVO</th>
            <!-- <th style="font-size: 11px;">DESCRIZIONE</th> -->
            <th style="font-size: 11px;" class="text-center">
              STATO
            </th>
            <th  style="font-size: 11px;" class="text-center">
              STATO CARICAMENTO
            </th>
            <th style="font-size: 11px;" class="text-center">
              # PUNTI
            </th>
            <th style="font-size: 11px;" class="text-center">
              # MISURAZIONI
            </th>
            <th style="font-size: 11px;" class="text-center">
              INTERVALO MISURAZIONI
            </th>
            <th *ngIf="userService.isAdmin" style="font-size: 11px;" class="text-center">
              AZIENDA
            </th>
            <th class="text-center" pSortableColumn="creato" style="font-size: 11px;">
              INSERITO
              <p-sortIcon field="creato"></p-sortIcon>
            </th>
            <th class="text-center" pSortableColumn="modificato" style="font-size: 11px;">
             ULTIMO CARICAMENTO
              <p-sortIcon field="modificato"></p-sortIcon>
            </th>
           
            <!-- <th pSortableColumn="scadenza" class="text-center" style="font-size: 11px;">
              SCADENZA
              <p-sortIcon field="scadenza"></p-sortIcon>
            </th>
            <th pSortableColumn="priorita" class="text-center" style="font-size: 11px;">
              PRIORITÀ
              <p-sortIcon field="priorita"></p-sortIcon>
            </th>
            <th pSortableColumn="areaDiCompetenza" class="text-center" style="font-size: 11px;">
              AREA
              <p-sortIcon field="areaDiCompetenza"></p-sortIcon>
            </th>
            <th pSortableColumn="tipo" class="text-center" style="font-size: 11px;">
              TIPO
              <p-sortIcon field="tipo"></p-sortIcon>
            </th>


            <th  class="text-center" style="font-size: 11px;" class="text-center">
              STATO

            </th>
            <th class="text-center" style="width: 8px;"></th>
            <th class="text-center" style="width: 8px;"></th> -->
            <th style="width: 8px;"></th>
            <th style="width: 8px;"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td> <span class="text-uppercase" style="font-size: 11px;font-weight: 500; font-weight: bold;" pTooltip="{{row.descrizione | uppercase}}" tooltipPosition="top"  >{{row.identificativo | uppercase}}</span></td>
           <!--  <td> <span class="text-uppercase" style="font-size: 11px;">{{row.descrizione | uppercase}}</span></td> -->
            <td class="text-center"> <span [ngClass]="stiliStatoProgetto[row.statoProgetto| uppercase]" class="text-uppercase" style="font-size: 11px;">{{row.statoProgetto | uppercase}}</span></td>
            <td class="text-center"> <span [ngClass]="stiliStatoCaricamento[row.statoCaricamento| uppercase]"  style="font-size: 11px;">{{row.statoCaricamento | uppercase}}</span></td>
            <td class="text-center"> <span class="text-uppercase" style="font-size: 12px; font-weight: bold;">{{row.numeroPunti}}</span></td>
            <td class="text-center"> <span class="text-uppercase" style="font-size: 12px; font-weight: bold;">{{row.numeroMisurazioni}}</span></td>
            <td class="text-center"> 
              <span *ngIf="row.statoCaricamento && row.dataInizioIntervalloCaricamenti" class="text-uppercase" style="font-size: 11px; font-weight: bold">{{utilService.formattaDataMisurazione(row.dataInizioIntervalloCaricamenti)}} : {{utilService.formattaDataMisurazione(row.dataFineIntervalloCaricamenti)}}
                
              </span>
            </td>
            
            <td *ngIf="userService.isAdmin" class="text-center"> 
              <span class="azienda"
              [ngStyle]="{ 'background-color': getAziendaColor(row.idAzienda) }"
              style="font-size: 11px;">
                {{row.ragioneSocialeAzienda | uppercase}}</span>
              </td>
            <td class="text-center"> <span class="text-lowercase" style="font-size: 11px;" tooltipPosition="top"  pTooltip="Modificato il {{utilService.formattaData(row.modificato)}}">{{utilService.formattaData(row.creato)}}</span></td>
            <td class="text-center"> 
              <i *ngIf="showCaricamentoInCorso(row.statoCaricamento| uppercase)" class="pi pi-spin pi-refresh" style="color:rgb(4, 1, 81)" tooltipPosition="top" pTooltip="Caricamento in corso"></i>

              <span class="text-lowercase" style="font-size: 12px;" pTooltip="{{row.durataUltimoCaricamento?('Durata '+row.durataUltimoCaricamento+ ' secondi'):''}}" tooltipPosition="top">{{row.dataUltimoCaricamento?utilService.formattaData(row.dataUltimoCaricamento):''}}</span>
            </td>
            <td  *ngIf="userService.isAdmin">
              <button
                pButton type="button" style="color:rgb(39, 128, 15)"
                icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text"
                (click)="openCaricaAzioni($event,row)">
              </button>
            </td>
            <td  *ngIf="!userService.isAdmin">
              <button pButton type="button" style="color:rgb(4, 61, 6);" 
              icon="pi pi-chevron-circle-right" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Apri Progetto"
              (click)="openViewer(row)" >
          </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      

      </div>
    </div>

  </div>
  <p-dialog [(visible)]="showDeleteConfirmation" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
    <div class="flex flex-column align-items-center my-4">
        <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
            <i class="pi pi-trash text-5xl"></i>
        </span>
        <div class="font-medium text-2xl text-900">ELIMINAZIONE PROGETTO</div>
    </div>
    <p class="line-height-3 p-0 m-0">
       Confermi di voler eliminare il PROGETTO  <span style="font-weight: bold;">{{selectedProgetto?selectedProgetto.identificativo.toUpperCase():''}}</span>?
    </p>
    <ng-template pTemplate="footer">
        <div class=" border-top-1 surface-border pt-3 flex">
            <button pButton pRipple icon="pi pi-times" styleClass="p-button-success" (click)="showDeleteConfirmation = false" label="ANNULLA" class="p-button-outlined w-6 mr-2"></button>
            <button pButton pRipple icon="pi pi-check" styleClass="p-button-warning" (click)="confermaEliminazioneProgetto()" label="CONFERMA" class="w-6 ml-2"></button>
        </div>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="showCaricaMisurazioniConfirmation" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
  <div class="flex flex-column align-items-center my-4">
      <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
          <i class="pi pi-chart-scatter text-4xl"></i>
      </span>
      <div class="font-medium text-2xl text-900 text-center">CARICAMENTO MISURAZIONI DEL PROGETTO <span style="font-weight: bold;">{{selectedProgetto?selectedProgetto.identificativo.toUpperCase():''}}</span></div>
  </div>
  <p class="line-height-3 p-0 m-0">
     Il caricamento <strong>SOVRASCRIVERA'</strong> le misurazioni già presenti, confermi di voler procedere?
  </p>
  <ng-template pTemplate="footer">
      <div class=" border-top-1 surface-border pt-3 flex">
          <button pButton pRipple icon="pi pi-times" styleClass="p-button-warning" (click)="showCaricaMisurazioniConfirmation = false" label="ANNULLA" class="p-button-outlined w-6 mr-2"></button>
          <button pButton pRipple icon="pi pi-check" styleClass="p-button-success" (click)="confermaCaricaMisurazioniProgetto()" label="CONFERMA" class="w-6 ml-2"></button>
      </div>
  </ng-template>
</p-dialog>
<p-dialog [(visible)]="showEliminaMisurazioniConfirmation" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
  <div class="flex flex-column align-items-center my-4">
      <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
          <i class="pi pi-eraser text-5xl"></i>
      </span>
      <div class="font-medium text-2xl text-900 text-center">ELIMINAZIONE MISURAZIONI DEL PROGETTO</div>
  </div>
  <p class="line-height-3 p-0 m-0 text-center">
     Confermi di voler eliminare tutte le misurazioni del progetto  <span style="font-weight: bold;">{{selectedProgetto?selectedProgetto.identificativo.toUpperCase():''}}</span>?
  </p>
  <ng-template pTemplate="footer">
      <div class=" border-top-1 surface-border pt-3 flex">
          <button pButton pRipple icon="pi pi-times" styleClass="p-button-success" (click)="showEliminaMisurazioniConfirmation = false" label="ANNULLA" class="p-button-outlined w-6 mr-2"></button>
          <button pButton pRipple icon="pi pi-check" styleClass="p-button-warning" (click)="confermaEliminaMisurazioniProgetto()" label="CONFERMA" class="w-6 ml-2"></button>
      </div>
  </ng-template>
</p-dialog>
<p-toast position="top-center"></p-toast>

  <ng-template #elseBlock>
    <home_not_logged></home_not_logged>
  </ng-template>
  <p-tieredMenu #menuAzioniTiered [popup]="true" [model]="menuAzioniItems"></p-tieredMenu>
</div>