
<ng-template [ngIf]="isCreatingProject">
      <app-crea-progetto-loading></app-crea-progetto-loading>
</ng-template>
    
<p-steps [model]="pagineCreaProgetto" [readonly]="true" [activeIndex]="activeIndex" ></p-steps>
    <div class="d-flex mt-2">
        <ng-container [ngSwitch]="activeIndex">
          <ng-container *ngSwitchCase="0">
            <form [formGroup]="creaProgettoForm">
                 <div class="grid formgrid p-fluid">
                    <div class="field mt-2 mb-2 col-8">
                        <label for="identificativo" class="font-medium text-900 text-green-600">IDENTIFICATIVO</label>
                        <span class="p-input-icon-left">
                            <i class="pi pi-bookmark"></i>
                            <input id="identificativo" type="text"  formControlName="identificativo" pInputText placeholder="Identificativo SENZA spazi">
                            
                        </span>
                        <small class="mt-3" *ngIf="creaProgettoForm.get('identificativo').hasError('noSpaces') && creaProgettoForm.get('identificativo').touched" >
                            L'identificativo NON deve contenere SPAZI.
                        </small>
                        <!-- <div *ngIf="creaProgettoForm.get('identificativo').hasError('noSpaces') && creaProgettoForm.get('identificativo').touched" class="error-message">
                            Il campo non deve contenere spazi.
                        </div> -->
                    </div>
                    <div class="field mt-2 mb-2 col-4">
                        <label for="azienda" class="font-medium text-900 text-blue-600">AZIENDA</label>
                        <span class="p-input-icon-left">
                            <i class="pi pi-bookmark"></i>
                            <p-dropdown inputId="azienda" [options]="aziende" optionLabel="ragioneSociale" [showClear]="true"
                                placeholder="Scegli l'azienda" formControlName="azienda">
                                <ng-template let-azienda pTemplate="item">
                                    <div class="flex align-items-center">
                                        <div [style]="{
                                            'background-color': azienda.colore ,
                                            'color': '#ffffff' ,
                                            'border-radius': '6px',
                                            'padding': '.25em .5rem',
                                            'font-weight': '700',
                                            'font-size': '13px',
                                            'letter-spacing': '.3px;',
                                            'cursor': 'pointer',
                                          }">{{azienda.ragioneSociale | uppercase}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </span>
                    </div>
                    <div class="field mt-2 mb-2 col-12">
                        <label for="descrizione" class="font-medium text-900 text-orange-400">DESCRIZIONE</label>
                        <span class="p-input-icon-left">
                            <i class="pi pi-info-circle"></i>
                            <textarea id="descrizione" rows="3" [autoResize]="true" formControlName="descrizione" pInputTextarea></textarea>
                        </span>
                    </div>
                </div> 
            </form>
          </ng-container>
          <ng-container *ngSwitchCase="1">
            <div class="grid mt-2 formgrid p-fluid">
                <div class="field mt-2 mb-2 col-6">
                  <p class="line-height-3 p-0 m-0 mb-1 mt-1">
                    Seleziona file <strong>QGIS</strong> o trascinalo nell'area sottostante
                   </p>
                        <!-- 
                         -->
                    <p-fileUpload
                        multiple="false"
                        maxFileSize="5000000"
                        accept=".qgs"
                        chooseLabel="Cerca file QGIS" 
                        uploadLabel="Carica file QGIS" 
                        cancelLabel="Cancella"
                        chooseIcon="pi pi-map-marker"
                        chooseStyleClass="btn-choose-qgisFile"
                        removeStyleClass="btn-remove-qgisFile"
                        uploadStyleClass="btn-choose-qgisFile"
                        [auto]="true"
                        [customUpload]="true"
                        (uploadHandler)="onQGISFileUpload($event)"
                        [showUploadButton]="false"
                        [showCancelButton]="false"
                        (onRemove)="onQGISFileRemove($event)"
                        >
                       <!--  <ng-template pTemplate="content">
                            <ul *ngIf="selectedFiles.length">
                                <li *ngFor="let file of selectedFiles">{{file.name}}</li>
                            </ul>
                        </ng-template> -->
                        <!-- <ng-template pTemplate="content">
                            <ul *ngIf="selectedFiles.length">
                                <li *ngFor="let file of selectedFiles">
                                    {{ file.name }} - {{ file.size }} bytes
                                </li>
                            </ul>
                        </ng-template> -->
                    </p-fileUpload>
                </div>
                <div class="field mt-2 mb-2 col-6">
                    <p class="line-height-3 p-0 m-0 mb-1 mt-1">
                      Seleziona file <strong>GEOPACKAGE</strong> o trascinalo nell'area sottostante
                     </p>
                     <p-fileUpload
                     multiple="false"
                     maxFileSize="5000000"
                     accept=".gpkg"
                     chooseLabel="Cerca file GEOPACKAGE" 
                     uploadLabel="Carica file GEOPACKAGE" 
                     cancelLabel="Cancella"
                     chooseStyleClass="btn-choose-geoPackageFile"
                     removeStyleClass="btn-remove-geoPackageFile"
                     uploadStyleClass="btn-choose-geoPackageFile"
                     chooseIcon="pi pi-map"
                     [auto]="true"
                     [customUpload]="true"
                     (uploadHandler)="onGeoPackageFileUpload($event)"
                     [showUploadButton]="false"
                     [showCancelButton]="false"
                     (onRemove)="onGeoPackageFileRemove($event)"

                     >
                   </p-fileUpload>
                  </div>
              </div>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <div class="grid mt-2 formgrid p-fluid">
                <div class="field mt-2 mb-2 col-12">
                  <p class="line-height-3 p-0 m-0 mb-1 mt-1">
                    Seleziona file <strong>CSV</strong> da caricare o trascinalo nell'area sottostante
                   </p>
                   <p-fileUpload
                   multiple="true"
                   maxFileSize="5000000"
                   accept=".csv"
                   chooseLabel="Cerca File CSV" uploadLabel="Carica File CSV" cancelLabel="Cancella"
                   [auto]="true"
                   [customUpload]="true"
                   (uploadHandler)="onMisurazioniFileUpload($event)"
                   [showUploadButton]="false"
                   [showCancelButton]="false"
                   chooseIcon="pi pi-database"
                    chooseStyleClass="btn-choose-misurazioniFile"
                    removeStyleClass="btn-remove-misurazioniFile"
                    uploadStyleClass="btn-choose-misurazioniFile"
                   (onRemove)="onFileMisurazioniRemove()"
                   >
                 </p-fileUpload>
                </div>
              </div>
          </ng-container>
        </ng-container>
    </div>




<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <!-- <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!creaProgettoForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button> -->
   <p-button icon="pi pi-arrow-left" class="p-button-text mr-2" (click)="activeIndex = activeIndex - 1" [disabled]="activeIndex === 0 "></p-button>
   <p-button icon="pi pi-arrow-right" class="p-button-text mr-1" (click)="changePage()" [disabled]="checkNextStep()"></p-button>
   <p-button *ngIf="activeIndex === pagineCreaProgetto.length -1" icon="pi pi-check" label="Conferma"
    class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)" [disabled]="isLoading"></p-button>
</div>
