

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { UtentiService } from "src/app/services/utenti.service";
import { Azienda } from "../../../models/fragile/azienda.model";
import { AziendeService } from "src/app/services/aziende.service";
import { UtilService } from "src/app/services/util.service";
import { Progetto } from "src/app/models/fragile/progetto.model";
import { ProgettiService } from "src/app/services/progetti.service";
import { noSpacesValidator } from "src/app/shared/no-space.validator";


@Component({
  templateUrl: "./dialog-crea-progetto.component.html",
  styleUrls: ["../home.component.css"],
})
export class CreaProgettoComponent implements OnInit {
  protected _onDestroy         = new Subject<void>();
  protected aziende: Azienda[]  = [];
  public creaProgettoForm: FormGroup;

  protected pagineCreaProgetto: MenuItem[];
  protected activeIndex: number = 0;
  
  protected selectedFiles: File[] = [];
  protected qgisFile: File;
  protected geoPackageFile: File;
  protected misurazioniFile: File;
  protected isFileUploaded: boolean = false;
  protected isCreatingProject: boolean = false;


  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private aziendeService: AziendeService,
    private progettiService: ProgettiService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
  ) {
    this.loadAziende();
  }

  ngOnInit(): void {
    this.creaProgettoForm = this._formBuilder.group({
      identificativo:     [null, [Validators.required, noSpacesValidator()]],
      azienda:            [null, [Validators.required]],
      descrizione:        [null]
    });
    this.inizializzaSteps();
  }

  inizializzaSteps() {
    this.pagineCreaProgetto = [
      {
        label: "Dettagli del progetto",
      },
      {
        label: "File QGIS e GEOPACKAGE",
      },
      {
        label: "File CSV delle misurazioni",
      }
    ];
    this.activeIndex = 0;
  }
  checkNextStep(): boolean {
    let disableNextStepButton = false;
    if (this.activeIndex === this.pagineCreaProgetto.length - 1) {
      disableNextStepButton = true;
    }/*  else if (this.activeIndex === 1) {
      disableNextStepButton = !(this.posizioneForm.get("coordinate")?.value);
    } */ else if (this.activeIndex === 0 && !this.creaProgettoForm.valid) {
      disableNextStepButton = true;
    }else if (this.activeIndex === 1 && (!this.geoPackageFile || !this.qgisFile ) ) {
      disableNextStepButton = true;
    }
    return disableNextStepButton;
  }
  changePage() {
    this.activeIndex = this.activeIndex + 1;
    
  }

  loadAziende(){
    this.logger.info("LOADING AZIENDE");
    this.aziendeService.get().subscribe( {
        next: (res) => {
            this.aziende = res;
        },
        error: (e) => {
            this.logger.info(e);
          this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di caricamento aziende'});
      },
        complete: () => {
        }
    });
}
  
onQGISFileUpload($event: { files: File[]; }) {
  this.qgisFile = $event.files[0];
}
onQGISFileRemove($event) {
  this.qgisFile = null;
}
onGeoPackageFileUpload($event) {
  this.geoPackageFile = $event.files[0];
}
onGeoPackageFileRemove($event) {
  this.geoPackageFile = null;
}
onMisurazioniFileUpload($event) {
  this.misurazioniFile = $event.files[0];
}
onFileMisurazioniRemove($event) {
  this.misurazioniFile = null;
}



  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let progettoDaCreare: Progetto = new Progetto();
      progettoDaCreare.identificativo = this.creaProgettoForm.value.identificativo;
      progettoDaCreare.descrizione    = this.creaProgettoForm.value.descrizione;
      progettoDaCreare.idAzienda      = this.creaProgettoForm.value.azienda.id;
      progettoDaCreare.fileMisurazioni = this.misurazioniFile;
      progettoDaCreare.fileGeoPackage = this.geoPackageFile;
      progettoDaCreare.fileQGis      = this.qgisFile;
      this.logger.info(progettoDaCreare);
      this.isCreatingProject = true;
      this.progettiService.crea(progettoDaCreare).subscribe( {
          next: (resCreaProgetto) => {
            this.isCreatingProject = false;
            this.logger.error(resCreaProgetto);
            this.dialogRef.close({ success: true });
          },
          error: (e) => {
              this.logger.error("ERRORE CREAZIONE PROGETTO" + e);
              this.dialogRef.close({ success: false });
              this.messageService.add({
                severity: "error",
                summary: "ATTENZIONE",
                detail: "Errore in fase di creazione di un nuovo progetto!", life:10000
              });
        },
          complete: () => {

            //this.isLoadingResults = false;
          }
      });/* .subscribe(
        (resCreaProgetto) => {
          this.logger.error(resCreaProgetto);
          this.dialogRef.close({ success: true });
        },
        (error) => {
          this.logger.error("ERRORE CREAZIONE PROGETTO" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione di un nuovo progetto!", life:10000
          });
        }
      ); */
     /* 
      this.aziendeService.crea(progettoDaCreare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE CREAZIONE AZIENDA" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione azienda!", life:10000
          });
        }
      ); */
    }
  }
}
